/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import PortableText from "../components/PortableText"
import { mq } from "../styles/utils"

export const query = graphql`
  query About {
    sanityInfo {
      _rawAbout
      _rawContact
    }
  }
`
const Philosophy = ({ data }) => {
  return (
    <section
      css={css`
        font-size: 1em;
        line-height: 1.8em;
        p {
          margin: 0 0 1.5em;
        }

        ${mq.bigscreen} {
          font-size: 1.6em;
          line-height: 1.5em;
        }
      `}
    >
      <SEO title="About" />
      <PortableText blocks={data.sanityInfo._rawAbout} />
      <div
        css={css`
          margin: 2em 0;
          ${mq.bigscreen} {
            font-size: 1.5em;
            line-height: 1.5em;
            a {
              display: inline-block;
              &:hover {
                text-decoration: none;
              }
            }
          }
        `}
      >
        <PortableText blocks={data.sanityInfo._rawContact} />
      </div>
    </section>
  )
}

export default Philosophy
